import React from 'react';
import { motion } from 'framer-motion';

interface StatusBadgeProps {
  status: 'draft' | 'active' | 'completed';
}

export function StatusBadge({ status }: StatusBadgeProps) {
  const variants = {
    draft: {
      bg: 'bg-gray-100 dark:bg-gray-700',
      text: 'text-gray-800 dark:text-gray-300',
      border: 'border-gray-200 dark:border-gray-600'
    },
    active: {
      bg: 'bg-green-50 dark:bg-green-900/20',
      text: 'text-green-700 dark:text-green-300',
      border: 'border-green-200 dark:border-green-800'
    },
    completed: {
      bg: 'bg-blue-50 dark:bg-blue-900/20',
      text: 'text-blue-700 dark:text-blue-300',
      border: 'border-blue-200 dark:border-blue-800'
    }
  };

  const { bg, text, border } = variants[status];

  return (
    <motion.span
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className={`inline-flex px-2.5 py-0.5 rounded-full text-xs font-medium border ${bg} ${text} ${border}`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </motion.span>
  );
}