import React from 'react';
import { motion } from 'framer-motion';
import { 
  Users, Map, LineChart, Sparkles, Zap, 
  Layers, Lock, GitMerge, Palette, MessageSquare,
  Share2, Database, ArrowRight
} from 'lucide-react';
import { Button } from '../ui/button';
import { Link } from 'react-router-dom';
import { Header } from '../landing/Header';
import { RetroGrid } from '../ui/retro-grid';
import { WordPullUp } from '../ui/word-pull-up';

const features = [
  {
    category: "Core Features",
    items: [
      {
        icon: Users,
        title: "AI-Powered Persona Creation",
        description: "Create detailed, data-driven personas in minutes. Our AI analyzes your customer data to generate accurate, actionable insights.",
        badge: "Popular"
      },
      {
        icon: Map,
        title: "Visual Journey Mapping",
        description: "Map customer journeys with our intuitive drag-and-drop interface. Identify pain points and opportunities across every touchpoint.",
        badge: "New"
      },
      {
        icon: LineChart,
        title: "Advanced Analytics",
        description: "Track key metrics, measure satisfaction scores, and identify trends with our comprehensive analytics dashboard."
      }
    ]
  },
  {
    category: "Collaboration",
    items: [
      {
        icon: Share2,
        title: "Real-Time Collaboration",
        description: "Work together with your team in real-time. Comment, share, and iterate on personas and journey maps simultaneously."
      },
      {
        icon: GitMerge,
        title: "Version Control",
        description: "Track changes, compare versions, and maintain a complete history of your personas and journey maps."
      },
      {
        icon: MessageSquare,
        title: "Feedback & Comments",
        description: "Gather feedback, discuss ideas, and make decisions directly within your projects."
      }
    ]
  },
  {
    category: "Customization & Integration",
    items: [
      {
        icon: Palette,
        title: "Custom Templates",
        description: "Start with our pre-built templates or create your own to match your unique needs and workflows."
      },
      {
        icon: Database,
        title: "Data Integration",
        description: "Connect with your existing tools and import data from various sources to enrich your personas and journeys."
      },
      {
        icon: Lock,
        title: "Enterprise Security",
        description: "Enterprise-grade security features including SSO, role-based access control, and data encryption."
      }
    ]
  }
];

const FeatureCard = ({ icon: Icon, title, description, badge }: {
  icon: any;
  title: string;
  description: string;
  badge?: string;
}) => (
  <motion.div
    whileHover={{ y: -4 }}
    className="relative group bg-white dark:bg-gray-800 rounded-xl p-6 shadow-sm border border-gray-200 dark:border-gray-700 hover:shadow-lg transition-all duration-300"
  >
    <div className="flex items-center gap-4 mb-4">
      <div className="p-3 rounded-lg bg-[#6C47FF]/10 text-[#6C47FF] dark:text-[#8165FF]">
        <Icon className="w-6 h-6" />
      </div>
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          {title}
        </h3>
        {badge && (
          <span className="inline-block text-xs font-medium text-[#6C47FF] dark:text-[#8165FF] bg-[#6C47FF]/10 dark:bg-[#8165FF]/20 px-2 py-0.5 rounded-full">
            {badge}
          </span>
        )}
      </div>
    </div>
    <p className="text-gray-600 dark:text-gray-300">
      {description}
    </p>
  </motion.div>
);

export default function Features() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <Header />
      
      {/* Hero Section */}
      <div className="relative pt-32 pb-20 overflow-hidden">
        <RetroGrid />
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="inline-flex items-center gap-2 mb-6 px-4 py-1.5 bg-[#6C47FF]/10 text-[#6C47FF] dark:text-[#8165FF] rounded-full text-sm font-medium"
          >
            <Sparkles className="w-4 h-4" />
            Powerful Features for Modern Teams
          </motion.div>
          
          <div className="mb-8">
            <WordPullUp
              words="Everything you need to create exceptional experiences"
              className="text-4xl sm:text-5xl lg:text-7xl font-display font-bold text-gray-900 dark:text-white tracking-tight text-center"
            />
          </div>

          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="max-w-2xl mx-auto text-lg sm:text-xl text-gray-600 dark:text-gray-300 mb-8 leading-relaxed"
          >
            Powerful tools for UX designers, product managers, and CX teams to understand customers,
            map journeys, and deliver outstanding experiences.
          </motion.p>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4 max-w-lg mx-auto"
          >
            <Link
              to="/signup"
              className="w-full sm:w-auto inline-flex items-center justify-center gap-2 h-14 px-8 font-medium text-white bg-[#6C47FF] hover:bg-[#5A3CD7] rounded-full transition-colors"
            >
              Get Started Free
              <ArrowRight className="w-5 h-5" />
            </Link>
            <Link
              to="/demo"
              className="w-full sm:w-auto inline-flex items-center justify-center h-14 px-8 bg-white hover:bg-gray-50 text-gray-600 hover:text-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 dark:hover:text-white font-medium rounded-full border border-gray-200 dark:border-gray-700 transition-colors"
            >
              Watch Demo
            </Link>
          </motion.div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {features.map((category, categoryIndex) => (
            <div key={category.category} className="mb-20 last:mb-0">
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className="text-2xl font-bold text-gray-900 dark:text-white mb-8"
              >
                {category.category}
              </motion.h2>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {category.items.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <FeatureCard {...feature} />
                  </motion.div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-20 bg-[#6C47FF]/5 dark:bg-[#6C47FF]/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl sm:text-4xl font-bold text-gray-900 dark:text-white mb-6"
          >
            Ready to transform your customer experience?
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="max-w-2xl mx-auto text-lg text-gray-600 dark:text-gray-300 mb-8"
          >
            Join thousands of teams already using Persona Paths to create
            exceptional customer experiences.
          </motion.p>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4"
          >
            <Link
              to="/signup"
              className="w-full sm:w-auto inline-flex items-center justify-center gap-2 h-14 px-8 font-medium text-white bg-[#6C47FF] hover:bg-[#5A3CD7] rounded-full transition-colors"
            >
              Start Free Trial
              <ArrowRight className="w-5 h-5" />
            </Link>
            <Link
              to="/contact"
              className="w-full sm:w-auto inline-flex items-center justify-center h-14 px-8 bg-white hover:bg-gray-50 text-gray-600 hover:text-gray-900 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 dark:hover:text-white font-medium rounded-full border border-gray-200 dark:border-gray-700 transition-colors"
            >
              Talk to Sales
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
}