import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Map, Plus, Search, Filter, SortAsc } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useApp } from '../../../context/AppContext';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { PageHeader } from '../../ui/PageHeader';
import { JourneyCard } from '../shared/JourneyCard';
import { EmptyState } from '../shared/EmptyState';
import { StatusBadge } from '../shared/StatusBadge';

export default function JourneyList() {
  const navigate = useNavigate();
  const { journeys, removeJourney, duplicateJourney } = useApp();
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState<'date' | 'name' | 'status'>('date');

  const filteredJourneys = journeys
    .filter(journey => {
      const matchesSearch = journey.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        journey.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilter = filter === 'all' || journey.status === filter;
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'status':
          return a.status.localeCompare(b.status);
        default:
          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      }
    });

  const handleCreateJourney = () => {
    navigate('/app/journeys/new');
  };

  const handleDeleteJourney = async (id: string) => {
    await removeJourney(id);
  };

  const handleDuplicateJourney = async (id: string) => {
    await duplicateJourney(id);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <PageHeader
        title="Journey Maps"
        description="Create and manage your customer journey maps"
        action={
          <Button
            onClick={handleCreateJourney}
            icon={Plus}
            className="bg-[#6C47FF] hover:bg-[#5A3CD7] text-white"
          >
            New Journey
          </Button>
        }
      />

      <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500" />
          <Input
            type="text"
            placeholder="Search journeys..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10"
          />
        </div>

        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-[#6C47FF]"
        >
          <option value="all">All Status</option>
          <option value="draft">Draft</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>

        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as 'date' | 'name' | 'status')}
          className="px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-[#6C47FF]"
        >
          <option value="date">Sort by Date</option>
          <option value="name">Sort by Name</option>
          <option value="status">Sort by Status</option>
        </select>
      </div>

      {filteredJourneys.length === 0 ? (
        <EmptyState
          icon={Map}
          title={searchTerm ? "No journeys found" : "Create your first journey"}
          description={searchTerm ? "Try adjusting your search terms" : "Start mapping your customer journeys"}
          action={
            <Button
              onClick={handleCreateJourney}
              icon={Plus}
              className="bg-[#6C47FF] hover:bg-[#5A3CD7] text-white"
            >
              Create Journey
            </Button>
          }
        />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <AnimatePresence>
            {filteredJourneys.map((journey, index) => (
              <motion.div
                key={journey.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ delay: index * 0.1 }}
              >
                <JourneyCard
                  journey={journey}
                  onEdit={() => navigate(`/app/journeys/${journey.id}`)}
                  onDelete={() => handleDeleteJourney(journey.id)}
                  onDuplicate={() => handleDuplicateJourney(journey.id)}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}