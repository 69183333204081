import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Map, X, Plus, Minus, Smile, Meh, Frown, Camera } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useApp } from '../../context/AppContext';
import { Button } from '../ui/button';
import { Badge } from '../ui/badge';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { PersonaSelector } from './shared/PersonaSelector';
import { ProgressSteps } from './shared/ProgressSteps';

interface TouchpointForm {
  id: string;
  name: string;
  description: string;
  customerAction: string;
  emotion: 'positive' | 'neutral' | 'negative';
  image?: string;
}

interface StageForm {
  id: string;
  name: string;
  touchpoints: TouchpointForm[];
}

interface JourneyForm {
  name: string;
  description: string;
  coverImage: string | null;
  personaIds: string[];
  stages: StageForm[];
}

export function CreateJourney() {
  const navigate = useNavigate();
  const { addJourney } = useApp();
  const [currentStage, setCurrentStage] = useState(0);
  const [form, setForm] = useState<JourneyForm>({
    name: '',
    description: '',
    coverImage: null,
    personaIds: [],
    stages: [
      { id: crypto.randomUUID(), name: 'Awareness & Discovery', touchpoints: [] },
      { id: crypto.randomUUID(), name: 'Consideration & Research', touchpoints: [] },
      { id: crypto.randomUUID(), name: 'Decision & Purchase', touchpoints: [] },
      { id: crypto.randomUUID(), name: 'Onboarding & Setup', touchpoints: [] },
      { id: crypto.randomUUID(), name: 'Retention & Growth', touchpoints: [] }
    ]
  });

  const renderStageNavigation = () => (
    <div className="relative mb-8">
      <div className="absolute inset-y-0 left-0 w-8 bg-gradient-to-r from-white to-transparent dark:from-gray-900 dark:to-transparent pointer-events-none z-10" />
      <div className="absolute inset-y-0 right-0 w-8 bg-gradient-to-l from-white to-transparent dark:from-gray-900 dark:to-transparent pointer-events-none z-10" />
      
      <div className="overflow-x-auto scrollbar-hidden mx-auto max-w-[calc(100vw-2rem)] lg:max-w-none">
        <div className="flex gap-2 px-4 pb-4">
          <button
            onClick={() => setCurrentStage(0)}
            className={`
              flex-none flex items-center gap-2 px-4 py-2 rounded-lg transition-all
              ${currentStage === 0
                ? 'bg-[#6C47FF] text-white'
                : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
              }
            `}
          >
            <span className="text-sm font-medium">Journey Details</span>
          </button>

          {form.stages.map((stage, index) => (
            <button
              key={stage.id}
              onClick={() => setCurrentStage(index + 1)}
              className={`
                flex-none flex items-center gap-2 px-4 py-2 rounded-lg transition-all min-w-max
                ${currentStage === index + 1
                  ? 'bg-[#6C47FF] text-white'
                  : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
                }
              `}
            >
              <span className="w-6 h-6 flex items-center justify-center rounded-full bg-black/10 dark:bg-white/10 text-sm">
                {index + 1}
              </span>
              <span className="text-sm font-medium whitespace-nowrap">{stage.name}</span>
              {stage.touchpoints.length > 0 && (
                <Badge variant="secondary" className="ml-1">
                  {stage.touchpoints.length}
                </Badge>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      const journey = {
        id: crypto.randomUUID(),
        name: form.name,
        description: form.description,
        coverImage: form.coverImage,
        personaIds: form.personaIds,
        status: 'draft' as const,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        stages: form.stages.map((stage, index) => ({
          id: stage.id,
          name: stage.name,
          order: index,
          touchpoints: stage.touchpoints.map(touchpoint => ({
            id: touchpoint.id,
            name: touchpoint.name,
            description: touchpoint.description,
            customerAction: touchpoint.customerAction,
            emotion: touchpoint.emotion,
            image: touchpoint.image,
            metrics: {
              satisfaction: 0,
              effort: 0,
              completion: 0
            }
          }))
        }))
      };

      await addJourney(journey);
      navigate('/app/journeys');
    } catch (error) {
      console.error('Failed to create journey:', error);
    }
  };

  const addTouchpoint = (stageIndex: number) => {
    const newStages = [...form.stages];
    newStages[stageIndex].touchpoints.push({
      id: crypto.randomUUID(),
      name: '',
      description: '',
      customerAction: '',
      emotion: 'neutral'
    });
    setForm({ ...form, stages: newStages });
  };

  const updateTouchpoint = (stageIndex: number, touchpointIndex: number, updates: Partial<TouchpointForm>) => {
    const newStages = [...form.stages];
    newStages[stageIndex].touchpoints[touchpointIndex] = {
      ...newStages[stageIndex].touchpoints[touchpointIndex],
      ...updates
    };
    setForm({ ...form, stages: newStages });
  };

  const removeTouchpoint = (stageIndex: number, touchpointIndex: number) => {
    const newStages = [...form.stages];
    newStages[stageIndex].touchpoints.splice(touchpointIndex, 1);
    setForm({ ...form, stages: newStages });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Create Journey Map</h1>
          <p className="text-gray-600 dark:text-gray-300">Map out your customer's journey stages and touchpoints</p>
        </div>
        <Button variant="ghost" size="icon" onClick={() => navigate('/app/journeys')}>
          <X className="w-5 h-5" />
        </Button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        {renderStageNavigation()}

        <AnimatePresence mode="wait">
          <motion.div
            key={currentStage}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-6"
          >
            {currentStage === 0 ? (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Journey Name
                  </label>
                  <Input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    placeholder="Enter a name for your journey map"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Description
                  </label>
                  <Textarea
                    value={form.description}
                    onChange={(e) => setForm({ ...form, description: e.target.value })}
                    placeholder="Describe the purpose and scope of this journey"
                    required
                    rows={4}
                  />
                </div>

                <PersonaSelector
                  selectedIds={form.personaIds}
                  onChange={(ids) => setForm({ ...form, personaIds: ids })}
                />
              </div>
            ) : (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  {form.stages[currentStage - 1].name}
                </h3>

                {form.stages[currentStage - 1].touchpoints.map((touchpoint, index) => (
                  <div key={touchpoint.id} className="p-4 bg-gray-50 dark:bg-gray-900/50 rounded-lg space-y-4">
                    <div className="flex items-center justify-between">
                      <Input
                        value={touchpoint.name}
                        onChange={(e) => updateTouchpoint(currentStage - 1, index, { name: e.target.value })}
                        placeholder="Touchpoint name"
                        className="flex-1 mr-2"
                      />
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => removeTouchpoint(currentStage - 1, index)}
                      >
                        <Minus className="w-4 h-4" />
                      </Button>
                    </div>

                    <Textarea
                      value={touchpoint.description}
                      onChange={(e) => updateTouchpoint(currentStage - 1, index, { description: e.target.value })}
                      placeholder="Describe what happens at this touchpoint"
                      rows={2}
                    />

                    <div className="flex gap-2">
                      <Button
                        type="button"
                        variant={touchpoint.emotion === 'positive' ? 'default' : 'ghost'}
                        onClick={() => updateTouchpoint(currentStage - 1, index, { emotion: 'positive' })}
                      >
                        <Smile className="w-4 h-4 mr-2" />
                        Positive
                      </Button>
                      <Button
                        type="button"
                        variant={touchpoint.emotion === 'neutral' ? 'default' : 'ghost'}
                        onClick={() => updateTouchpoint(currentStage - 1, index, { emotion: 'neutral' })}
                      >
                        <Meh className="w-4 h-4 mr-2" />
                        Neutral
                      </Button>
                      <Button
                        type="button"
                        variant={touchpoint.emotion === 'negative' ? 'default' : 'ghost'}
                        onClick={() => updateTouchpoint(currentStage - 1, index, { emotion: 'negative' })}
                      >
                        <Frown className="w-4 h-4 mr-2" />
                        Negative
                      </Button>
                    </div>
                  </div>
                ))}

                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => addTouchpoint(currentStage - 1)}
                  className="w-full"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Touchpoint
                </Button>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className="flex justify-between">
          <Button
            type="button"
            variant="ghost"
            onClick={() => setCurrentStage(Math.max(0, currentStage - 1))}
            disabled={currentStage === 0}
          >
            Previous
          </Button>
          
          {currentStage === form.stages.length ? (
            <Button type="submit">
              Create Journey
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => setCurrentStage(currentStage + 1)}
            >
              Next
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}

export default CreateJourney;